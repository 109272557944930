<template>
  <div class="a1">
    <img src="../assets/主页.png" alt="">
    
    <div class="a2">
      <!-- 标题栏 -->
            <div class="tag" style="margin-top:20px">
          <div class="tag_1"><div @click="goto()" style="display:inline-block;margin-right:24px"> <span class="tag_2">首页</span></div><div style="display:inline-block;margin-right:24px">></div><div style="display:inline-block">产品功能</div></div>
          <el-divider></el-divider>
      </div>
      <div class="product">


        <!-- 系统管理 -->
        <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product13.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible1 = true"
            ></el-image>

            <span>系统管理</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible1 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="系统管理"
            :visible.sync="dialogVisible1"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >统计分析-功能介绍 一、用户权限<br />
              1.用户指所有使用本系统的用户，包括信息主管部门、医院各科室系统管理员、厂商等。<br />
              2.用户角色分为医院主管领导、信息化领导、系统管理员、普通用户、厂商用户。<br />
              3.用户权限根据模块进行划分，普通用户还须受限于相关系统。<br />
              二、字典管理<br />
              1.系统所有相关的基础字典，可进行调整，配置，设置。<br />
              三、系统配置<br />
              1.日志审批流程定义。<br />
              2.工单审批流程定义。<br />
              3.预算管理流程定义，根据申请类型、金额等参数定义审批流程。<br />
              4.项目管理流程定义，结合医院制定和项目管理规范定义软硬件项目的流程。<br />
              5.需求审批流程定义。<br />
              6.运维审批流程定义。<br />
            </span>
            <el-image
              :src="require('../assets/product/product13.png')"
              
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible1 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible1 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!--  统计分析-->
         <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product12.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible2 = true"
            ></el-image>

            <span>统计分析</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible2 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="统计分析"
            :visible.sync="dialogVisible2"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >统计分析-功能介绍<br/>

项目完成情况统计，按预算和非预算、交付项目、运维项目统计。对项目中存入的数据进行对应统计生成报表等。

            </span>
            <el-image
              :src="require('../assets/product/product12.png')"
             
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible2 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!-- 知识库 -->
        <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product30.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible3 = true"
            ></el-image>

            <span>知识库</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible3 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="知识库"
            :visible.sync="dialogVisible3"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >知识库-功能介绍<br/>

一、法律法规<br/>
1.国家相关法律法规、医院相关制度、计算机管理相关制度、信息系统相关制度等。<br/>
2.用户只支持查询和浏览，不支持复制与导出。<br/>
3.系统支持WORD、PDF、图片等文件上传。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product31.png')"
              
            ></el-image><br/>
            <span>
                二、相关标准<br/>
1.信息系统相关的标准和指引，如三级医院评审、互联互通标准、电子病历评级标准等。<br/>
2.系统支持对每一个具体的标准细则进行定义，包括标准要求、标准解释、验证方式等。<br/>
3.系统支持WORD、PDF、图片等文件上传。<br/>
            </span>
             <el-image
              :src="require('../assets/product/product32.png')"
              
            ></el-image><br/>
            <span>
                三、系统文档<br/>
1.信息系统相关的操作说明书、维护手册等。<br/>
2.系统支持WORD、PDF、图片等文件上传。<br/>
3.信息系统相关的知识库文档等。<br/>
            </span>
              <el-image
              :src="require('../assets/product/product33.png')"
              
            ></el-image><br/>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible3 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible3 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!-- 实施管理 -->
         <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product41.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible4 = true"
            ></el-image>

            <span>实施管理</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible2 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="实施管理"
            :visible.sync="dialogVisible4"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >实施管理-功能介绍<br/>

一、实施管理根据项目管理内容将总体内容拆分为五大过程：启动，规划，执行，收尾，监控。<br/>

二、项目标准流程可以设定交付文档或相关内容：<br/>
系统调研：调研方案、调研报告。<br/>
项目进场：项目进场申请，启动会议。<br/>
实施方案：实施方案，审批。<br/>
环境配置：服务器配置，客户端配置，机房配置。<br/>
项目范围：设定项目范围分组。<br/>
实施计划：基本信息，计划详情。<br/>
字典配置：医院字典、科室字典、用户字典、项目类型等基础字典，字典确认。<br/>
系统接口：HIS接口、LIS接口、集成平台接口方案审核，接口状态确认。<br/>
用户培训：培养主题、开始时间结束时间、培训对象、培训课件、培训签到表、考核表。<br/>
系统联调：联调申请、联调报告。<br/>
范围核对：对应项目范围模块设定的项目内容。<br/>
验收申请：核对项目范围、项目需求、遗留问题等<br/>
维护方案：根据系统提供模板，填写维护方案。<br/>
项目总结：竣工总结。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product42.png')"
             
            ></el-image>
            <span>
               <br/> 三、需求管理<br/>
1.项目过程中的所有需求都须提交到系统中，包括项目范围、二次开发需求、系统BUG等<br/>
2.项目需求同时也包括项目过程中须协调的事项，以不同的事务类型标记<br/>
3.需求审批流程可以配置，如业务科室申请后，信息部门审批，发送到项目组评估，需求完成后发送通知给申请人和相关人员，可对本次需求完成情况进行评价<br/>
4.系统实时监控需求的执行计划，预警提醒开发人员，超时通知项目经理和信息领导<br/>
            </span>
            <el-image
              :src="require('../assets/product/product43.png')"
             
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible2 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible2 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
        <!--  合同管理-->
         <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product51.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible5 = true"
            ></el-image>

            <span>合同管理</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible5 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="合同管理"
            :visible.sync="dialogVisible5"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >合同管理-功能介绍<br/>

1.合同管理包括文件管理、风险登记、纠纷登记、合同交付。<br/>
2.合同签订后，可以根据付款方式关联项目进度，如上线付第二笔上线款。<br/>
3.发票审核流程可根据合同类型进行自定义，如经办科室、经办人、合同金额等。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product51.png')"
             
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible5 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible5 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
         <!-- 选型管理 -->
         <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product60.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible6 = true"
            ></el-image>

            <span>选型管理</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible6 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="实施管理"
            :visible.sync="dialogVisible6"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >选型管理-功能介绍<br/>

选型管理分为四个任务过程：方案征集，演示论证，项目考察，招标采购。<br/>
一、方案征集<br/>
1.系统方案征集，包括各厂商提供的厂商资质、系统方案、系统报价等。<br/>
2.系统自动匹配厂商方案内容，核对功能是否符合用户要求。<br/>
3.进行对功能需求的确认。<br/>
<br/>
            </span>
            <el-image
              :src="require('../assets/product/product61.png')"
             
            ></el-image>
            <span>
                二、演示论证<br/>
1.系统提供演示论证管理，如演示论证内容确认、演示地点、参会人员等。<br/>
2.支持对每一次的会议的参会人员通知，并且会后进行评论和总结。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product62.png')"
             
            ></el-image>
             <span>
                三、项目考察<br/>
1.系统提供项目考察管理，如考察内容确认、考察标题、日期、地点等。<br/>
2.支持对每一次考察人员进行通知，并且考察后进行评论和总结。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product63.png')"
             
            ></el-image>
             <span>
                四、招标采购<br/>
系统提供用户需求书管理，支持在线填写总体目标和用户需求，支持上传电子文档。<br/>
            </span>
            <el-image
              :src="require('../assets/product/product64.png')"
             
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible6 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible6 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
          <!-- 采购管理 -->
        <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product70.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible7 = true"
            ></el-image>

            <span>采购管理</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible7 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="采购管理"
            :visible.sync="dialogVisible7"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >采购申请-功能介绍<br />

1、项目申请是指信息化相关的信息系统或软硬件项目，不涉及医院非信息相关的申请。申请可以是业务科室发起，也可以是信息部门发起，如检验科发起检验系统项目申请，信息科发起网络安全改造申请等。<br />
2、系统提供项目申请表在线填写，包括系统概况、功能需求、项目意义等。<br />
3、系统提供可行性研究报告填写，支持上传PDF、WORD等报告文档。<br />
4、系统提供在线填写项目评估与论证报告。<br />
5、系统提供项目申请查询功能，业务科室只能查询本科室相关的项目申请，信息部门可以根据权限查询对应的项目申请<br />
            </span>
            <el-image
              :src="require('../assets/product/product70.png')"
              
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible7 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible7 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
          <!-- 首页 -->
        <div class="product_1">
          <div class="p">
            <el-image
              class="product_1_1"
              :src="require('../assets/product/product80.png')"
              style="width: 100%; height: 100%"
              @click="dialogVisible8 = true"
            ></el-image>

            <span>首页</span>
          </div>

          <!-- <el-button type="text" @click="dialogVisible7 = true"
            >点击打开 Dialog</el-button
          > -->

          <el-dialog
            title="采购管理"
            :visible.sync="dialogVisible8"
            width="1200px"
            :before-close="handleClose"
          >
            <span
              >首页-功能介绍<br/>
1、我的审批：与该账号关联的项目中，跟自身有关联的审批信息。<br/>
2、我的需求：在平台系统中，账号提交的在各个不同的项目里的需求列表。<br/>
3、我的项目：平台中所有配置到了该账号的项目列表。<br/>
4、审批涵盖系统支持的所有审批流程，包括项目申请审批、采购审批、合同审批、需求审批、项目汇报等，流程是否审批可根据项目配置决定，如业务科室提出的需求是否需要业务科室主任审批。<br/>
5、在项目简报中，可以看到相关的项目数量，正常推进和延期项目的数量和百分比，每个阶段中项目数量图以及三十天内重要里程碑的图例展示。<br/>
6、需求简报：<br/>
       1、需求简报中统计了所有需求的总数，其中分为待审批、开发中、待更新的数量和占比/<br/>
       2、统计计划内的，发生了变更的和逾期的数量和所占比例。<br/>
       3、针对变更数和逾期数生成折线图。<br/>
7、需求逾期量排名：系统分析后查询出提出需求但逾期的厂商，对其进行排名，统计出逾期数量和占比<br/>
8、项目动态：项目动态中展现出来的是简版的项目里程碑，根据项目的内容出现推进或者变更时，动态发生改变。         </span>
            <el-image
              :src="require('../assets/product/product80.png')"
              
            ></el-image>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible8 = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible8 = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
       dialogVisible7: false,
        dialogVisible8: false,
    };
  },
  methods: {
      goto(){
          this.$router.replace('/Home1')
      },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

/* 去下划线 */

.router-link {
  text-decoration: none;
}

.a1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  
}
.a1 .a2 {
    margin: auto;
  width: 1200px;
}
/* 标签栏 */
.a1 .a2 .tag_1:hover{
     cursor: pointer;
}
.a1 .a2 .tag_2:hover{
    color: skyblue;
}


/*  */
.a1 .a2 .product{
    display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     
     align-content:flex-start;
}
.a1 .a2 .product .product_1 {
  padding-top: 5px;
  margin-left: 28px;
}
/* 图片文字说明 */
.a1 .a2 .product .product_1 .p {
  position: relative;

  width: 360px;
  height: 240px;
  margin-bottom: 24px;
}
.a1 .a2 .product .product_1 .p span {
  width:350px;
  display: block;
  padding-left: 10px;
  line-height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  /*background-color: #333;*/
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  color: white;
  text-align: center;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 浮动 */
.a1 .a2 .product .product_1 .p {
  cursor: pointer;
  background-color: #fff;

  border: none;

  border-radius: 6px;

  -webkit-transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);

  transition: all 250ms cubic-bezier(0.02, 0.01, 0.47, 1);
}
.a1 .a2 .product .product_1 .p:hover {
  cursor: pointer;
  box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.3);

  transform: translate(0, -5px);

  transition-delay: 0s !important;
}

.box-shadow {
  -webkit-box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.15);

  box-shadow: 0 4px 16px rgba(48, 55, 66, 0.3);
}
</style>
